import React from "react";
import { motion } from "framer-motion";
import { FaSearch, FaExpand,  FaCheckCircle   } from "react-icons/fa";
import { Link } from "react-router-dom";
// Search Bar Component
export const SearchBar = ({ onSearch }) => (
  <div className="flex items-center bg-gray-100 p-3 rounded-lg shadow-md">
    <FaSearch className="text-gray-500 mr-2" />
    <input
      type="text"
      placeholder="Search products..."
      className="w-full bg-transparent outline-none"
      onChange={(e) => onSearch(e.target.value)}
    />
  </div>
);

// Filters Component
export const Filters = ({ categories, onFilter }) => (
    <div className="flex gap-2">
      {categories.map((category, index) => (
        <button
          key={index}
          className="px-3 py-2 bg-gray-200 rounded-lg hover:bg-[#e5752b] hover:text-white transition"
          onClick={() => onFilter(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );
// Product Card Component
export const ProductCard = ({ product }) => (
    <motion.div
      className="bg-white p-4 rounded-xl shadow-lg hover:shadow-2xl transition-all"
      whileHover={{ scale: 1.05 }}
    >
      {/* Scrollable Image Container */}
      <div className="h-60 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 rounded-md">
        <img src={product.image} alt={product.name} className="w-full object-cover" />
      </div>
      
      <h3 className="text-lg font-semibold mt-2">{product.name}</h3>
      <div className="p-4 bg-gray-100 rounded-lg shadow-sm mt-3">
  <h3 className="text-lg font-semibold text-[#e5752b] mb-2"> Description</h3>
  <ul className="text-gray-700 space-y-2">
    <li className="flex items-center gap-2">
      <FaCheckCircle /> <span className="font-medium">No of Pages:</span> {product.pages}
    </li>
    <li className="flex items-center gap-2">
    <FaCheckCircle /> <span className="font-medium"> {product.tabMenu} Tab Menu:</span>
    </li>
    <li className="flex items-center gap-2">
    <FaCheckCircle /> <span className="font-medium">Video/Images Gallery:</span> Available
    </li>
    <li className="flex items-center gap-2">
    <FaCheckCircle /> <span className="font-medium">Downloadable Product ( PDF, PPT ):
      </span>
    </li>
    <li className="flex items-center gap-2">
    <FaCheckCircle /> <span className="font-medium"> Social Media Link:
      </span>
    </li>
  </ul>
</div>

     
      
     <Link to={product.url} target="_blank"><button className="mt-3 w-full bg-[#e5752b] text-white py-2 rounded-lg flex items-center justify-center gap-2">
        <FaExpand />Live Preview
      </button>
      </Link> 
    </motion.div>
  );
  

// Product Grid
export const ProductGrid = ({ products }) => (
  <div className="grid md:grid-cols-3 gap-6 mt-6">
    {products.map((product) => (
      <ProductCard key={product.id} product={product} />
    ))}
  </div>
);

// Pagination Component
export const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex justify-center gap-2 mt-6">
    {Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index}
        className={`px-4 py-2 rounded-lg ${currentPage === index + 1 ? 'bg-[#1c709f] text-white' : 'bg-gray-200'}`}
        onClick={() => onPageChange(index + 1)}
      >
        {index + 1}
      </button>
    ))}
  </div>
);