import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`left-[50%] rounded-full fixed w-full max-w-7xl mx-auto mt-4 z-50 transition-all duration-300 backdrop-blur-md animate-float  ${isScrolled ? 'bg-gradient-to-r from-[var(--main-color)] to-[var(--main2-color)] text-white shadow-[0px 8px 30px rgba(0, 0, 0, 0.5)]' : 'bg-white/20 text-white shadow-[0px 8px 30px rgba(0, 0, 0, 0.5)]'}`} style={{ transform: "translateX(-50%)" }}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-[90px] overflow-hidden"> 
          {/* Logo */}
          <a href="/" className="flex items-center transform transition-transform duration-300 ">
            <img src="digitalRanker.png" alt="SalePush Logo" className="max-w-[200px] transform transition-transform duration-300 hover:scale-105" />
          </a>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex space-x-8">
            <Link to="/" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">Home</Link>
            <Link to="/about-us" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">About Us</Link>
            <Link to="/our-services" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">Services</Link>
            <Link to="/packages" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">Packages</Link>
            <Link to="/our-brands" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">Brands</Link>
            <Link to="/catalogues" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">Web Themes</Link>
            <Link to="/contact-us" className="text-black text-lg md:text-xl font-semibold hover:text-yellow-300 transition duration-300 transform hover:scale-105">Contact Us</Link>
          </nav>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-white"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isMobileMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-white rounded-lg shadow-lg">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a href="#home" className="block px-3 py-2 text-gray-800 hover:text-blue-600">Home</a>
              <a href="#services" className="block px-3 py-2 text-gray-800 hover:text-blue-600">Services</a>
              <a href="#about" className="block px-3 py-2 text-gray-800 hover:text-blue-600">About</a>
              <a href="#portfolio" className="block px-3 py-2 text-gray-800 hover:text-blue-600">Portfolio</a>
              <a href="#contact" className="block px-3 py-2 text-gray-800 hover:text-blue-600">Contact</a>
            </div>
          </div>
        )}
      </div>
    </header>
  );    
};

export default Header;