import React from 'react';
import { motion } from 'framer-motion';

const Awards = () => {
  const awards = [
    {
      title: "Affiliate Marketing",
      platform: "Silicon India",
      year: "2023",
      icon: "images/award.png",
      color: "#01C4E2"
    },
  
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-6xl font-bold text-white mb-6">
            RECOGNITIONS & AWARD
          </h2>
          <p className="text-gray-300 text-lg max-w-2xl mx-auto">
            Offer a wide range of services to help businesses establish and enhance their online presence.
          </p>
          <div className="w-20 h-1 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] mx-auto mt-6" />
        </motion.div>

     

        {/* Awards Table */}
        <div className="flex-col justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mt-20"
        >
          <div className="overflow-x-auto">
            <table className="w-full text-left ">
              <thead>
                <tr className="border-b border-gray-800 ">
                  <th className="py-4 px-6 text-gray-400">Award</th>
                  <th className="py-4 px-6 text-gray-400">Platform</th>
                  <th className="py-4 px-6 text-gray-400">Year</th>
                </tr>
              </thead>
              <tbody >
                {awards.map((award, index) => (
                  <motion.tr
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className=" border-b border-gray-800 hover:bg-white/5 transition-colors duration-200"
                  >
                    <td className="py-4 px-6 text-white">{award.title}</td>
                    <td className="py-4 px-6 text-gray-300">{award.platform}</td>
                    <td className="py-4 px-6">
                      <span 
                        className="px-3 py-1 rounded-full text-sm"
                        style={{ backgroundColor: `${award.color}20`, color: award.color }}
                      >
                        {award.year}
                      </span>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
            </motion.div>

        {awards.map((award, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group col-8"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-black to-gray-900 rounded-2xl transform -rotate-2 group-hover:rotate-0 transition-transform duration-300" />
              <motion.div
               
                className="relative bg-gradient-to-r from-gray-900 to-black p-2 rounded-2xl border-2 border-opacity-20 hover:border-opacity-40 transition-all duration-300"
                style={{ borderColor: award.color }}
              >
                {/* Trophy Icon */}
                <div 
                  className="text-6xl  transform group-hover:scale-105 transition-transform duration-300"
                  style={{ color: award.color }}
                >
                  <img src={award.icon} alt={award.title} className="img-fluid" />
                </div>


                {/* Hover Effect */}
                <div 
                  className="absolute bottom-0 left-0 h-1 w-0 group-hover:w-full transition-all duration-300 rounded-full"
                  style={{ backgroundColor: award.color }}
                />
              </motion.div>
            </motion.div>
          ))}
        </div>

        {/* Decorative Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute h-px w-full"
              style={{
                background: `linear-gradient(90deg, transparent, ${awards[i % awards.length].color}, transparent)`,
                top: `${25 + i * 25}%`,
                opacity: 0.1
              }}
              animate={{
                x: ['-100%', '100%']
              }}
              transition={{
                repeat: Infinity,
                duration: 10 + i * 2,
                ease: 'linear'
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Awards; 