import React from "react";
import { motion } from "framer-motion";

const Title = ({ text }) => {
  return (
    <div className="w-full bg-neutral-300 text-center py-8 pt-[150px]">
      <motion.h1 
        className="text-4xl font-extrabold text-[#e5752b] uppercase tracking-wide"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {text}
      </motion.h1>
      <motion.div 
        className="w-24 h-1 bg-[#1c709f] mx-auto mt-3 rounded-full"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5 }}
      ></motion.div>
    </div>
  );
};

export default Title;