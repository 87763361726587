import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Title from '../components/Title';

import OurBrands from '../components/OurBrands';

const OurBrandsPage = () => {
   useEffect(() => {
        document.title = "Our Brands - Digital Ranker";
      }, []);
  return (
    <div className="min-h-screen">
    <Header />
    <Title text="Our Brands"/>
    <OurBrands />
    <Footer />
      {/* Other sections */}
    </div>
  );
};

export default OurBrandsPage;