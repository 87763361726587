import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Title from '../components/Title';
import Solutions from '../components/Solutions';

const Services = () => {
   useEffect(() => {
        document.title = "Our Services - Digital Ranker";
      }, []);
  return (
    <div className="min-h-screen">
    <Header />
    <Title text="Our Services"/>
    <Solutions />
    <Footer />
      {/* Other sections */}
    </div>
  );
};

export default Services;