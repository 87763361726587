import React, { useState } from 'react';
import { 
  FaLinkedinIn, 
  FaFacebookF, 
  FaTwitter, 
  FaInstagram 
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();
    // Add subscription logic here
    setEmail('');
  };

  const offices = [
    {
      name: "Head Office",
      address: "Plot No.270, Udyog Vihar Phase-II, GUrugram-122016(Haryana)"
    },
    {
      name: "Noida Office",
      address: "H-23, Sector 63, Noida, Uttar Pradesh - 201301"
    },
    {
      name: "Chandigarh Office",
      address: "SCO-18,Basement, Sector 16, Panchkula, Haryana 134109"
    },
    {
      name: "Ahmedabad Office",
      address: "SG8, 2nd Floor, Om Complex, C.G. Road, Ahmedabad - 380004, Gujarat"
    }
  ];

  const solutions = [
    "Search Engine Optimization",
    "Google Adwords(PPC)",
    "Social Media Optimization",
    "Web Designing & Development",
    "Social Media Marketing",
    "Domain & Hosting"
  ];

  const companyLinks = [
    {
      name:"About Us",
      url: "/about-us",
    },
    {
      name:"Services",
      url: "/our-services",
    },
    {
      name:"Packages",
      url: "/packages",
    },
    {
      name:"Brands",
      url: "/our-brands",
    },
    {
      name:"Web Themes",
      url: "/catalogues",
    },
    {
      name:"Contact Us",
      url: "/contact-us",
    },
  ];

  const socialLinks = [
    { 
      name: "Linkedln", 
      icon: <FaLinkedinIn className="text-xl" />, 
      url: "#" 
    },
    { 
      name: "Facebook", 
      icon: <FaFacebookF className="text-xl" />, 
      url: "#" 
    },
    { 
      name: "Twitter", 
      icon: <FaTwitter className="text-xl" />, 
      url: "#" 
    },
    { 
      name: "Instagram", 
      icon: <FaInstagram className="text-xl" />, 
      url: "#" 
    }
  ];

  return (
    <footer className="bg-black text-white pt-20 relative overflow-hidden">
      {/* Background Gradients */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Subscribe Section */}
        <div className="max-w-2xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold mb-6">Don't Miss! Subscribe!</h2>
          <form onSubmit={handleSubscribe} className="flex gap-4 max-w-md mx-auto">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
              className="flex-1 px-4 py-2 rounded-lg bg-white/10 border border-white/20 focus:border-[#01C4E2] outline-none"
            />
            <button
              type="submit"
              className="px-6 py-2 rounded-lg bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] hover:opacity-90 transition-opacity"
            >
              Subscribe
            </button>
          </form>
        </div>

        {/* Main Footer Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* Offices */}
          <div>
            <h3 className="text-xl font-semibold mb-6">Our Offices</h3>
            <div className="space-y-4">
              {offices.map((office, index) => (
                <div key={index} className="text-gray-400">
                  <p className="font-medium text-white">{office.name}</p>
                  <p className="text-sm">{office.address}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Company Links */}
          <div>
            <h3 className="text-xl font-semibold mb-6">Company</h3>
            <ul className="space-y-3">
              {companyLinks.map((link, index) => (
                <li key={index}>
                  <Link to={link.url} className="text-gray-400 hover:text-[#01C4E2] transition-colors">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Solutions */}
          <div>
            <h3 className="text-xl font-semibold mb-6">Our Solutions</h3>
            <ul className="space-y-3">
              {solutions.map((solution, index) => (
                <li key={index}>
                  <a href="#" className="text-gray-400 hover:text-[#01C4E2] transition-colors">
                    {solution}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
          <div className="flex justify-center">
            <img src="digitalRanker.png" alt="Footer Logo" className=" mb-6 bg-white rounded-lg shadow-lg max-w-[200px] mx-auto" />
          </div>
            <div className="space-y-4 text-gray-400">
              <p className="flex items-center gap-2">
                <span className="font-semibold text-white">Phone:</span>
                <a href="tel:+919958039666">+91 9958 0396 66</a>
              </p>
              <p className="flex items-center gap-2">
                <span className="font-semibold text-white">Email:</span>
                <a href="mailto:info@oneloginsuiteinc.com">info@oneloginsuiteinc.com</a>
              </p>
            </div>

            {/* Social Links */}
            <div className="flex gap-4 mt-6">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center hover:bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] transition-all duration-300 group"
                  aria-label={social.name}
                >
                  <span className="text-white group-hover:scale-110 transition-transform">
                    {social.icon}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-white/10 py-6 mt-12">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-sm">
              Copyright 2024 | Design By One Login Digital Media
            </p>
            <a href="!#" className="text-gray-400 text-sm hover:text-[#01C4E2] transition-colors">
              Our Terms & Condition
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 