import React from "react";

const packages = [
  {
    category: "Google Adword Management",
    plans: [
      {
        price: "90K/Month",
        features: [
          "Complete Ads Management of Google Ads",
          "Budget Adjustment & Consumer Targeting",
          "Regular Account Optimization",
          "Performance-Based Strategy",
          "ROI Enhancement Techniques",
          "Display Ads, Search Ads, Lead Ads, Directory Ads",
        ],
        color: "bg-yellow-500",
      },
      {
        price: "1.5 Lacs/Year",
        features: [
          "Complete Ads Management of Google Ads",
          "Budget Adjustment & Consumer Targeting",
          "Regular Account Optimization",
          "Performance-Based Strategy",
          "ROI Enhancement Techniques",
          "Display Ads, Search Ads, Lead Ads, Directory Ads",
        ],
        color: "bg-purple-600",
      },
    ],
  },
  {
    category: "SMO - Social Media Optimisation",
    plans: [
      {
        price: "70K/Month",
        features: [
          "Complete Management of SMO",
          "Facebook Page Creation & Optimization",
          "Instagram Business Setup",
          "YouTube Channel Management",
          "Engaging Posts and Content Strategies",
          "Ad Campaign Execution",
        ],
        color: "bg-yellow-500",
      },
      {
        price: "1.2 Lacs/6 Months",
        features: [
          "All Features of Monthly Plan",
          "Regular Performance Reports",
          "ROI Tracking & Adjustments",
          "Dedicated Support",
        ],
        color: "bg-blue-600",
      },
      {
        price: "1.8 Lacs/Year",
        features: [
          "All Features of 6 Months Plan",
          "Advanced Growth Strategy",
          "Complete Brand Awareness Campaign",
        ],
        color: "bg-green-600",
      },
    ],
  },
  {
    category: "Digital India SME Plan",
    plans: [
      {
        price: "INR 3.5 Lacs",
        features: [
          "Dynamic Website",
          "SEO Optimization",
          "Facebook & Google Ads",
          "Social Media Branding",
          "Business Page Promotions",
        ],
        color: "bg-yellow-700",
      },
    ],
  },
];

const PackageCard = ({ plan }) => (
  <div className={`p-6 rounded-xl shadow-2xl ${plan.color} text-white hover:scale-105 transition-transform duration-300 relative overflow-hidden` }>
    <div className="absolute inset-0 bg-black opacity-30 hover:opacity-10 transition-opacity duration-500"></div>
    <div className="relative z-10">
      <h2 className="text-2xl font-bold mb-3 border-b pb-2 shadow-md">{plan.price}</h2>
      <ul className="list-none space-y-2 mt-4">
        {plan.features.map((feature, index) => (
          <li key={index} className="text-sm flex items-center gap-2">
            <span className="w-2 h-2 bg-white rounded-full"></span> {feature}
          </li>
        ))}
      </ul>
    </div>
    <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-transparent to-black opacity-60"></div>
  </div>
);

export default function pricingPackages() {
  return (
    <div className="p-10 bg-gray-950 text-gray-100 min-h-screen">
      {packages.map((pack, index) => (
        <div key={index} className="mb-12">
          <h1 className="text-3xl font-extrabold mb-5 border-b-2 pb-2 border-gray-700 uppercase tracking-wide text-gray-300">{pack.category}</h1>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {pack.plans.map((plan, idx) => (
              <PackageCard key={idx} plan={plan} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
