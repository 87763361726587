import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import Home from './pages/Home.jsx'
import { createBrowserRouter, RouterProvider} from 'react-router-dom'
import App from './App.jsx'
import About from './pages/About.jsx'
import Packages from './pages/Packages.jsx'
import Services from './pages/Services.jsx'
import OurBrandsPage from './pages/OurBrandsPage.jsx'
import CataloguePage from './pages/CataloguePage.jsx'
import ContactPage from './pages/ContactPage.jsx'




const router = createBrowserRouter([
  {
    path:"/",
    element: <App />, // App acts as the layout
    children: [ 
      { path: "/", element: <Home /> },
      { path: "/about-us", element: <About /> },
      { path: "/our-services", element: <Services /> },
      { path: "/packages", element: <Packages /> },
      { path: "/our-brands", element: <OurBrandsPage /> },
      { path: "/catalogues", element: <CataloguePage /> },
      { path: "/contact-us", element: <ContactPage /> },
    ],
  }
])
createRoot(document.getElementById('root')).render(
  <StrictMode>
   <RouterProvider router={router} />
  </StrictMode>,
)
