import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const PartnersSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const partners = [
    [
      {
        name: 'brand1',
        logo: 'images/brands/brand1.png'
      },
      {
          name: 'brand2',
          logo: 'images/brands/brand2.png'
      },
      {
        name: 'brand3',
        logo: 'images/brands/brand3.png'
      },
      {
        name: 'brand4',
        logo: 'images/brands/brand4.png'
      },
      {
        name: 'brand5',
        logo: 'images/brands/brand5.png'
      }
    ],
    [
      {
        name: 'brand6',
        logo: 'images/brands/brand6.png'
      },
      {
        name: 'brand7',
        logo: 'images/brands/brand7.png'
      },
      {
        name: 'brand8',
        logo: 'images/brands/brand8.png'
      },
      {
        name: 'brand9',
        logo: 'images/brands/brand9.png'
      },
      {
        name: 'brand10',
        logo: 'images/brands/brand10.png'
      },
    ],
    [
      
      {
        name: 'brand11',
        logo: 'images/brands/brand11.png'
      },
      {
        name: 'brand12',
        logo: 'images/brands/brand12.png'
      },
      {
        name: 'brand13',
        logo: 'images/brands/brand13.png'
      },
      {
        name: 'brand15',
        logo: 'images/brands/brand15.png'
      },
      {
        name: 'brand35',
        logo: 'images/brands/brand35.jpg'
      },
     
    ]
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % partners.length);
    }, 4000);
    return () => clearInterval(timer);
  }, [partners.length]);

  return (
    <section className="py-16 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            Our Trusted Partners
          </h2>
          <div className="w-20 h-1 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] mx-auto" />
        </motion.div>

        <div className="relative overflow-hidden">
          <motion.div
            animate={{
              x: `-${currentSlide * 100}%`
            }}
            transition={{ duration: 0.5 }}
            className="flex"
          >
            {partners.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className="min-w-full grid grid-cols-2 md:grid-cols-5 gap-8"
              >
                {group.map((partner, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    className="bg-white/10 p-8 rounded-xl backdrop-blur-sm hover:bg-white/20 transition-all duration-300 border border-[#01C4E2]/20"
                    style={{
                      background: `linear-gradient(45deg, rgba(1,196,226,0.1), rgba(251,157,8,0.1))`
                    }}
                  >
                    <img
                      src={partner.logo}
                      alt={partner.name}
                      className=" mx-auto object-contain bg-white rounded-xl p-1"
                    />
                  </motion.div>
                ))}
              </div>
            ))}
          </motion.div>
        </div>

        {/* Navigation Dots */}
        <div className="flex justify-center space-x-3 mt-8">
          {partners.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full transition-all duration-300 ${
                currentSlide === index 
                  ? 'bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] w-8' 
                  : 'bg-white/50'
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnersSlider; 