import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const OurBrands = () => {
  const [currentBrand, setCurrentBrand] = useState(0);

  const brands = [
    {
      title: "One Login Properties!",
      description: "Providing Verified Lead. Discover your dream home effortlessly. Explore diverse properties and expert guidance for a seamless buying experience.",
      website: "https://oneloginproperties.com",
      color: "#01C4E2"
    },
    {
      title: "uPVC Doors & Window",
      description: "uPVC windows and doors helps you to increase your Business. UPVC Doors & Windows become popular in present days and extensively used in housing facilities.",
      website: "https://upvcdoorswindows.com",
      color: "#FB9D08"
    },
    {
      title: "Furnitures Dekho",
      description: "As furnitures Dekho become more aware of their customer content benefits, it will only increase customers' desire to see this kind of media within their shopping journey.",
      website: "https://furnituresdekho.com",
      color: "#E13282"
    },
    {
      title: "Pharma Franchise",
      description: "Leading PCD Pharma Franchise platform connecting pharmaceutical companies with distributors across India.",
      website: "https://pharmafranchise.club",
      color: "#B99068"
    }
  ];

  useEffect(() => {
    if (brands.length === 0) return; // Prevent errors if brands is empty
  
    const timer = setInterval(() => {
      setCurrentBrand((prev) => (prev + 1) % brands.length);
    }, 9000);
  
    return () => clearInterval(timer);
  }, [brands.length]); // ✅ Add brands.length as a dependency
  

  return (
    <section className="py-20 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-6xl font-bold text-white mb-6">
            Our Brands
          </h2>
          <div className="w-20 h-1 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] mx-auto" />
        </motion.div>

        {/* Brands Slider */}
        <div className="relative">
          <AnimatePresence mode='wait'>
            <motion.div
              key={currentBrand}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="grid md:grid-cols-2 gap-8 items-center"
            >
              {/* Content */}
              <div className="p-8">
                <motion.h3
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="text-3xl md:text-4xl font-bold mb-6"
                  style={{ color: brands[currentBrand].color }}
                >
                  {brands[currentBrand].title}
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="text-gray-300 text-lg mb-8"
                >
                  {brands[currentBrand].description}
                </motion.p>
                <motion.a
                  href={brands[currentBrand].website}
                  target="_blank"
                  rel="noopener noreferrer"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="px-8 py-3 rounded-full text-white font-semibold transition-all duration-300 transform hover:-translate-y-1 inline-block"
                  style={{ 
                    background: `linear-gradient(45deg, ${brands[currentBrand].color}, ${brands[currentBrand === brands.length - 1 ? 0 : currentBrand + 1].color})`
                  }}
                >
                  Visit Website
                </motion.a>
              </div>

              {/* Website Iframe */}
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.2 }}
                className="relative group h-[600px] rounded-2xl overflow-hidden shadow-2xl"
              >
                <div 
                  className="absolute inset-0 opacity-20 blur-xl transform group-hover:scale-110 transition-transform duration-500"
                  style={{ background: brands[currentBrand].color }}
                />
                <iframe
                  src={brands[currentBrand].website}
                  title={brands[currentBrand].title}
                  className="w-full h-full rounded-2xl transform group-hover:scale-105 transition-transform duration-500"
                  style={{ 
                    border: `2px solid ${brands[currentBrand].color}`,
                    backgroundColor: 'white'
                  }}
                  loading="lazy"
                  sandbox="allow-same-origin allow-scripts"
                />
              </motion.div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation */}
          <div className="flex justify-center space-x-3 mt-12">
            {brands.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentBrand(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  currentBrand === index 
                    ? 'w-8 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08]' 
                    : 'bg-white/50'
                }`}
              />
            ))}
          </div>

          {/* Side Navigation Arrows */}
          <button
            onClick={() => setCurrentBrand((prev) => (prev - 1 + brands.length) % brands.length)}
            className="absolute left-0 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() => setCurrentBrand((prev) => (prev + 1) % brands.length)}
            className="absolute right-0 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      {/* Decorative Lines */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute h-px w-full"
            style={{
              background: `linear-gradient(90deg, transparent, ${brands[i % brands.length].color}, transparent)`,
              top: `${25 + i * 25}%`,
              opacity: 0.1
            }}
            animate={{
              x: ['-100%', '100%']
            }}
            transition={{
              repeat: Infinity,
              duration: 10 + i * 2,
              ease: 'linear'
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default OurBrands; 