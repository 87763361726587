import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Title from '../components/Title';

import Contact from '../components/Contact';

const ContactPage = () => {
   useEffect(() => {
      document.title = "Contact Us - Digital Ranker";
    }, []);
  

  return (
    <div className="min-h-screen">
    <Header />
    <Title text="Contact Us"/>
 <div className="container w-50">
 <Contact />
 </div>
    <Footer />
      {/* Other sections */}
    </div>
  );
};

export default ContactPage;