import React from "react";
import { motion } from 'framer-motion';
import { FaLightbulb, FaUsers, FaChartLine, FaShieldAlt } from "react-icons/fa";

const coreValues = [
  { icon: <FaLightbulb />, title: "Innovation", description: "We strive for creative solutions and cutting-edge technology." },
  { icon: <FaUsers />, title: "Collaboration", description: "Teamwork and strong partnerships drive our success." },
  { icon: <FaChartLine />, title: "Growth", description: "We focus on continuous improvement and scaling new heights." },
  { icon: <FaShieldAlt />, title: "Integrity", description: "Honesty and transparency are at the heart of our business." },
];

const CoreValues = () => {
  return (
    <section className="py-10 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
    {/* Background Effects */}
    <div className="absolute inset-0">
      <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
      <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
    </div>

    <h2 className="text-4xl md:text-5xl font-bold text-white text-center pb-10 "> 
        <span className="bg-gradient-to-r from-[var(--main-color)] to-[var(--main2-color)] text-transparent bg-clip-text">Our Core Values </span>
    </h2>
      <div className="grid md:grid-cols-4 gap-6 px-6">
        {coreValues.map((value, index) => (
          <div key={index} className="bg-white p-6 rounded-2xl shadow-md hover:shadow-lg transition-all">
            <div className="text-4xl text-[#1c709f] mb-4">{value.icon}</div>
            <h3 className="text-xl font-semibold text-gray-800">{value.title}</h3>
            <p className="text-gray-600 mt-2">{value.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CoreValues;