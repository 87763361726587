import React from 'react'
import { Outlet } from "react-router-dom";
function App() {
 
  return (
    <div>
      
      <Outlet /> {/* This is where routed components will be rendered */}
    </div>
  ) 
}

export default App