import React from 'react';
import { motion } from 'framer-motion';

const WhoWeAre = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
      
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
            
            Who We Are 
            </h2>
            <div className="h-1 w-20 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] mb-8" />
            
            {/* Certifications */}
          

            <p className="text-gray-300 text-lg mb-8">
            Are you keen to leverage the web for overwhelming results? Digital marketing is the solution with high potential. It is not a destination, but a step by step process with so many channels, media formats, strategies and techniques, only an experienced digital marketing company is well-versed with. The first step for successful journey of digital marketing is to reach a right service provider. So, contact Digital Rankers to discuss your needs and priorities.
            </p>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] rounded-full text-white font-semibold hover:shadow-lg hover:shadow-[#01C4E2]/50 transition-all duration-300"
            >
              About More
            </motion.button>
          </motion.div>

          {/* Right Image Grid */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="grid grid-cols-2 gap-4"
          >
        
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative group mt-2"
            >
              <div className="absolute inset-0  rounded-lg" />
              <h2 className="text-4xl md:text-5xl font-bold text-white mb-0 mt-5 ">
            
            <span className="bg-gradient-to-r from-[var(--main-color)] to-[var(--main2-color)] text-transparent bg-clip-text">Digital Ranker </span>Certified Certification.
                        </h2>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative group mt-2"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#B99068]/20 to-[#E13282]/20 group-hover:opacity-100 transition-opacity duration-300 rounded-lg" />
              <img 
                src="images/certificate/google-1.webp" 
                alt="Our Team"
                className="rounded-lg w-full h-full object-cover"
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative group mt-2"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#B99068]/20 to-[#E13282]/20 group-hover:opacity-100 transition-opacity duration-300 rounded-lg" />
              <img 
                src="images/certificate/google-1.webp" 
                alt="Our Team"
                className="rounded-lg w-full h-full object-cover"
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative group mt-2"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#B99068]/20 to-[#E13282]/20 group-hover:opacity-100 transition-opacity duration-300 rounded-lg" />
              <img 
                src="images/certificate/google-1.webp" 
                alt="Our Team"
                className="rounded-lg w-full h-full object-cover"
              />
            </motion.div>
          
          </motion.div>
        </div>

        {/* Stats Counter */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-20"
        >
          {[
            { number: '100+', label: 'Happy Clients', color: '#01C4E2' },
            { number: '50+', label: 'Projects Completed', color: '#FB9D08' },
            { number: '10+', label: 'Years Experience', color: '#E13282' },
            { number: '24/7', label: 'Support Available', color: '#9ED43E' }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <motion.div
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="text-4xl md:text-5xl font-bold mb-2"
                style={{ color: stat.color }}
              >
                {stat.number}
              </motion.div>
              <p className="text-gray-400">{stat.label}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default WhoWeAre; 