import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const BannerSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      image: "images/banner/SMM.jpg",
      title: "Digital Marketing",
      subtitle: "Transform Your Business",
      description: "Innovative solutions for the digital age",
      color: "from-blue-600 to-purple-600"
    },
    {
      image: "images/banner/GROW.jpg",
      title: "SEO Excellence",
      subtitle: "Rank Higher, Achieve More",
      description: "Dominate search engine rankings",
      color: "from-green-600 to-teal-600"
    },
    {
      image: "images/banner/SMO.jpg",
      title: "Social Growth",
      subtitle: "Connect & Engage",
      description: "Build your social media presence",
      color: "from-purple-600 to-pink-600"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <section className="relative h-screen overflow-hidden">
      <AnimatePresence mode='wait'>
        {slides.map((slide, index) => (
          index === currentSlide && (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.7 }}
              className="absolute inset-0"
            >
              <div 
                className="absolute inset-0 bg-cover bg-center"
                style={{ 
                  backgroundImage: `url(${slide.image})`,
                }}
              />
              <div className="relative h-full flex top-2/3">
                <div className="container mx-auto px-4">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                    className="max-w-4xl mx-auto text-white text-center"
                  >
                   
                    <motion.div className="flex justify-center gap-4">
                    <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] rounded-full text-white font-semibold hover:shadow-lg hover:shadow-[#01C4E2]/50 transition-all duration-300"
            >
             Get Started
            </motion.button>
                    <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3  border-2 border-white rounded-full text-white font-semibold hover:shadow-lg hover:shadow-[#01C4E2]/50 transition-all duration-300 hover:bg-gradient-to-r from-[#01C4E2] to-[#FB9D08]"
            >
             Learn More
            </motion.button>
                      
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          )
        ))}
      </AnimatePresence>

      {/* Slider Navigation */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-3">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === index ? 'bg-white w-8' : 'bg-white/50'
            }`}
          />
        ))}
      </div>
    </section>
  );
};

export default BannerSlider; 