import React, { useEffect } from 'react';
import BannerSlider from '../components/BannerSlider';
import WhoWeAre from '../components/WhoWeAre';
import PartnersSlider from '../components/PartnersSlider';
import Solutions from '../components/Solutions';
import OurBrands from '../components/OurBrands';
import Awards from '../components/Awards';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Home = () => {
  useEffect(() => {
    document.title = "Home - Digital Ranker";
  }, []);
  return (
    <div className="min-h-screen">
      <Header />
      <BannerSlider />
      <WhoWeAre />
      <PartnersSlider />
      <Solutions />
      <Awards />
      <Testimonials />
      <Footer />
      {/* Other sections */}
    </div>
  );
};

export default Home;