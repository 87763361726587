import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import WhoWeAre from '../components/WhoWeAre';
import Awards from '../components/Awards';
import Testimonials from '../components/Testimonials';
import Title from '../components/Title';
import CoreValues from '../components/CoreValue';

const About = () => {
   useEffect(() => {
      document.title = "About Us - Digital Ranker";
    }, []);
  return (
    <div className="min-h-screen">
    <Header />
    <Title text="About Us"/>
    <CoreValues />
    <WhoWeAre />
    <Testimonials />
    <Awards />
    <Footer />
      {/* Other sections */}
    </div>
  );
};

export default About;