import { SearchBar, Filters, ProductGrid, Pagination } from "../components/Catalogues";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Title from "../components/Title";
import React, { useEffect, useState } from "react";

const CataloguePage = () => {
   useEffect(() => {
      document.title = "Web Themes - Digital Ranker";
    }, []);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All"); // Default to 'All'

  const products = [
    { id: 1, name: "FMCG", category: "FMCG", image: "images/catalogue_img/theame-1.webp", url: "https://oneloginsuiteinc.com/lp/catalog/1/", pages: "10" , tabMenu:'Custom' },

    { id: 2, name: "B2B & Manufacturing", category: "B2B & Manufacturing", image: "images/catalogue_img/theame-1.jpg", url: "https://oneloginsuiteinc.com/lp/catalog/2/", pages: "5" , tabMenu:'Fixed' },
    
    { id: 3, name: "B2B & Manufacturing", category: "B2B & Manufacturing", image: "images/catalogue_img/theame-2.jpg", url: "https://oneloginsuiteinc.com/lp/catalog/3/", pages: "10" , tabMenu:'Fixed' },

    { id: 4, name: "B2B & Manufacturing", category: "B2B & Manufacturing", image: "images/catalogue_img/theame-6.jpg", url: "https://oneloginsuiteinc.com/lp/catalog/4/", pages: "10" , tabMenu:'Custom' },

    { id: 5, name: "B2B & Manufacturing", category: "B2B & Manufacturing", image: "images/catalogue_img/theame-5.jpg", url: "https://oneloginsuiteinc.com/lp/catalog/5/", pages: "10" , tabMenu:'Custom' },
    
    { id: 6, name: "B2B & Manufacturing", category: "B2B & Manufacturing", image: "images/catalogue_img/theame-7.jpg", url: "https://oneloginsuiteinc.com/lp/catalog/6/", pages: "10" , tabMenu:'Custom' },

  ];

  const categories = ["All", "FMCG", "B2B & Manufacturing", "Education", "PCD Pharma", "Real Estate", "UPVC", "interior", "Furniture", ];

  // Filtering Logic
  const filteredProducts = products.filter(
    (product) =>
      (filter === "All" || product.category === filter) &&
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen">
      <Header />
      <Title text="Web Themes" />
      <div className="container mx-auto px-6 py-10 relative overflow-hidden">
        <div className="flex justify-between items-center mb-6">
          <SearchBar onSearch={setSearchTerm} />
          <Filters categories={categories} onFilter={setFilter} />
        </div>
        <ProductGrid products={filteredProducts} />
        <Pagination currentPage={1} totalPages={3} onPageChange={() => {}} />
      </div>
      <Footer />
    </div>
  );
};

export default CataloguePage;
