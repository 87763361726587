import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const testimonials = [
    {
      name: "Zakir Hussain",
      company: "Servokon Systems Limited",
      content: "Great work, One Login Digital Media! Exactly as per my requirements and beyond! Great communication skills and a fantastic job done within a tight deadline. Many thanks, One Login Digital Media!",
      image: "/path-to-image.jpg", // Add actual image paths
      color: "#01C4E2"
    },
    {
      name: "Mr. Kamal Jain",
      company: "Swastik Interchem Private Limited",
      content: "When I signed in 'One Login Digital Media' for their services, I was little hesitant as I am not very aware about internet marketing and how to use it to best of my benefits",
      image: "/path-to-image.jpg",
      color: "#FB9D08"
    },
    {
      name: "Dr. Vivek Mehta",
      company: "Pulastya Skin Clinc",
      content: "Just a brief acknowledgement to say thank you for your time and effort. In Service Industry it is difficult to measure service and such-like, but I have to say that I am always left with a warm and fuzzy feeling",
      image: "/path-to-image.jpg",
      color: "#B99068"
    },
    {
      name: "Mr Jaydeep Patel",
      company: "Rajendra Industries",
      content: "I was in search of an Internet marketing firm which works basis my needs and helps me get a robust internet presence. During this search I met many firms, but one firm which gave me exactly what I was looking for, was One Login Digital Media.",
      image: "/path-to-image.jpg",
      color: "#E13282"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [testimonials.length]);

  return (
    <section className="py-20 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-6xl font-bold text-white mb-6">
            Testimonials
          </h2>
          <div className="w-20 h-1 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] mx-auto" />
        </motion.div>

        {/* Testimonials Slider */}
        <div className="relative max-w-5xl mx-auto">
          <AnimatePresence mode='wait'>
            <motion.div
              key={currentTestimonial}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="relative"
            >
              {/* Quote Icon */}
              <div 
                className="absolute -top-10 left-0 text-8xl opacity-20"
                style={{ color: testimonials[currentTestimonial].color }}
              >
                "
              </div>

              {/* Testimonial Content */}
              <div className="bg-gradient-to-r from-gray-900 to-black p-8 md:p-12 rounded-2xl border-2 border-opacity-20 hover:border-opacity-40 transition-all duration-300"
                style={{ borderColor: testimonials[currentTestimonial].color }}
              >
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-gray-300 text-lg md:text-xl mb-8 italic"
                >
                  {testimonials[currentTestimonial].content}
                </motion.p>

                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                  className="flex items-center"
                >
                  <div 
                    className="w-16 h-16 rounded-full bg-gradient-to-r from-gray-800 to-gray-700 flex items-center justify-center text-2xl font-bold"
                    style={{ color: testimonials[currentTestimonial].color }}
                  >
                    {testimonials[currentTestimonial].name.charAt(0)}
                  </div>
                  <div className="ml-4">
                    <h4 className="text-white font-semibold text-lg">
                      {testimonials[currentTestimonial].name}
                    </h4>
                    <p 
                      className="text-sm"
                      style={{ color: testimonials[currentTestimonial].color }}
                    >
                      {testimonials[currentTestimonial].company}
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation Dots */}
          <div className="flex justify-center space-x-3 mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentTestimonial(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  currentTestimonial === index 
                    ? 'w-8 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08]' 
                    : 'bg-white/50'
                }`}
              />
            ))}
          </div>

          {/* Navigation Arrows */}
          <button
            onClick={() => setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 p-3 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all hidden md:block"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() => setCurrentTestimonial((prev) => (prev + 1) % testimonials.length)}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 p-3 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all hidden md:block"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      {/* Decorative Lines */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute h-px w-full"
            style={{
              background: `linear-gradient(90deg, transparent, ${testimonials[i % testimonials.length].color}, transparent)`,
              top: `${25 + i * 25}%`,
              opacity: 0.1
            }}
            animate={{
              x: ['-100%', '100%']
            }}
            transition={{
              repeat: Infinity,
              duration: 10 + i * 2,
              ease: 'linear'
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default Testimonials; 