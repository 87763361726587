import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Title from '../components/Title';

import PricingPackages from '../components/Pricing';

const Packages = () => {
   useEffect(() => {
        document.title = "Packages - Digital Ranker";
      }, []);
  return (
    <div className="min-h-screen">
    <Header />
    <Title text="Packages"/>
    <PricingPackages />
    <Footer />
      {/* Other sections */}
    </div>
  );
};

export default Packages;