import React from 'react';
import { motion } from 'framer-motion';

const Solutions = () => {
  const solutions = [
    {
      icon: 'images/svg/seo-animate.svg',
      title: 'Search Engine Optimization',
      description: 'Boost your website ranking and visibility in search results',
      color: '#01C4E2'
    },
    {
      icon: 'images/svg/money-income-animate.svg',
      title: 'Google Adwords (PPC)',
      description: 'Drive targeted traffic and maximize ROI with PPC campaigns',
      color: '#FB9D08'
    },
    {
      icon: 'images/svg/social-tree-animate.svg',
      title: 'Social Media Optimization',
      description: 'Optimize your social presence for better engagement',
      color: '#B99068'
    },
    {
      icon: 'images/svg/web-devices-animate.svg',
      title: 'Web Designing & Development',
      description: 'Create stunning, responsive websites that convert',
      color: '#E13282'
    },
    {
      icon: 'images/svg/social-growth-animate.svg',
      title: 'Social Media Marketing',
      description: 'Build and grow your brand across social platforms',
      color: '#9ED43E'
    },
    {
      icon: 'images/about-us/domain_hosting.png',
      title: 'Domain & Hosting',
      description: 'Secure and reliable hosting solutions for your website',
      color: '#01C4E2'
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#01C4E2]/10 rounded-full filter blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#FB9D08]/10 rounded-full filter blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative z-10">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-6xl font-bold text-white mb-6">
            Solution Provide
          </h2>
          <div className="text-2xl md:text-3xl font-semibold mb-6 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] text-transparent bg-clip-text">
            TO PROVIDE SMART SOLUTION.
          </div>
          <p className="text-gray-300 text-lg max-w-2xl mx-auto">
            Offer a wide range of services to help businesses establish and enhance their online presence.
          </p>
        </motion.div>

        {/* Solutions Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {solutions.map((solution, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ 
                scale: 1.05,
                transition: { duration: 0.2 }
              }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-black to-gray-900 rounded-2xl transform -rotate-2 group-hover:rotate-0 transition-transform duration-300" />
              <div 
                className="relative bg-gradient-to-r from-gray-900 to-black p-8 rounded-2xl border-2 border-opacity-20 hover:border-opacity-40 transition-all duration-300"
                style={{ borderColor: solution.color }}
              >
                <div 
                  className="text-4xl mb-4 p-3 rounded-full w-[200px] h-[200px] flex items-center justify-center mx-auto"
                  style={{ backgroundColor: `${solution.color}20` }}
                >
                 <img src={solution.icon} alt={solution.title} />
                </div>
                <h3 
                  className="text-xl font-bold mb-3"
                  style={{ color: solution.color }}
                >
                  {solution.title}
                </h3>
                <p className="text-gray-400">
                  {solution.description}
                </p>
                <motion.div
                  className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  whileHover={{ scale: 1.1 }}
                >
                  <div 
                    className="w-8 h-8 rounded-full flex items-center justify-center"
                    style={{ backgroundColor: solution.color }}
                  >
                    <svg 
                      className="w-4 h-4 text-white" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M9 5l7 7-7 7" 
                      />
                    </svg>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Bottom CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mt-16"
        >
          <button className="px-8 py-3 bg-gradient-to-r from-[#01C4E2] to-[#FB9D08] rounded-full text-white font-semibold hover:shadow-lg hover:shadow-[#01C4E2]/50 transition-all duration-300 transform hover:-translate-y-1">
            Explore All Solutions
          </button>
        </motion.div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute h-px w-full"
            style={{
              background: `linear-gradient(90deg, transparent, ${[
                '#01C4E2',
                '#FB9D08',
                '#E13282'
              ][i]}, transparent)`,
              top: `${25 + i * 25}%`,
              opacity: 0.1
            }}
            animate={{
              x: ['-100%', '100%']
            }}
            transition={{
              repeat: Infinity,
              duration: 10 + i * 2,
              ease: 'linear'
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default Solutions; 